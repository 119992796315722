import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import SanityBlockContent from '@sanity/block-content-to-react';
import BackgroundMap from '../components/BackgroundMap';
import { Header } from '../components/Header';
import Hands from '../assets/images/ActivityCardHands.png';
import MichaelImage from '../assets/images/ActivityCardMichael.png';
import { Calendar, CalendarSVG } from '../assets/icons/Calendar';
import { WorkPin, WorkPinSVG } from '../assets/icons/WorkPin';
import { CalendarPin, CalendarPinSVG } from '../assets/icons/CalendarPin';
import { BlueDot, BlueDotSVG } from '../assets/icons/BlueDot';
import { ActivityCard } from '../components/ActivityCard';
import { TextSection } from '../components/TextSection';
import { serializers } from '../utils/serializer';
import { TextContainer } from '../components/TextContainer';
import { ImageMapUpsideDown } from '../components/BackgroundMapUpsideDown';
import {
  container,
  containerPage,
  item,
  itemPage,
} from '../utils/framer-motion-config';

export default function CommunityBuilderPage({ data }) {
  const sanityContent = data.communityBuilder.contentSections;
  const LastPartOfSanityContent = sanityContent.slice(1);

  return (
    <CommunityBuilderContainer
      variants={containerPage}
      initial="hidden"
      animate="show"
    >
      <ImageMapUpsideDown />
      <CommunityBuilderContent variants={itemPage}>
        <TextContainer>
          {sanityContent[0] && (
            <TextSection>
              <Header big bolder>
                {sanityContent[0].title}
              </Header>

              <SanityBlockContent
                blocks={sanityContent[0]._rawText}
                serializers={serializers}
                projectId={process.env.SANITY_PROJECT_ID}
                dataset={process.env.SANITY_DATASET}
              />
            </TextSection>
          )}

          {LastPartOfSanityContent.map((content, index) => (
            <TextSection key={index}>
              <Header>{content.title}</Header>
              <SanityBlockContent
                blocks={content._rawText}
                serializers={serializers}
                projectId={process.env.SANITY_PROJECT_ID}
                dataset={process.env.SANITY_DATASET}
              />
            </TextSection>
          ))}
        </TextContainer>

        <Calendar size={89} variants={item} />
      </CommunityBuilderContent>

      <BackgroundMapCustom
        variants={container}
        image
        overlay
        color="var(--lightGrey)"
      >
        <ActivityCardHands src={Hands} alt="" variants={item} />
        <WorkPin variants={item} />
        <CalendarPin variants={item} />
        <ActivityCardMichael src={MichaelImage} alt="" variants={item} />
        <BlueDot variants={item} />
      </BackgroundMapCustom>
    </CommunityBuilderContainer>
  );
}

export const query = graphql`
  query CommunityBuilderQuery {
    communityBuilder: sanityPage(
      id: { eq: "-332ad1db-4b0f-51af-b1cb-03d26eaa12f4" }
    ) {
      contentSections {
        ... on SanityTextSection {
          title
          subtitle
          _rawText
        }
      }
    }
  }
`;

const ActivityCardHands = styled(ActivityCard)``;

const ActivityCardMichael = styled(ActivityCard)``;

const CommunityBuilderContainer = styled(motion.div)`
  background-color: var(--lightGrey);
  transition: 0.2s ease-in-out;
`;

const CommunityBuilderContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  max-width: var(--gridWidth);
  margin: 0 auto;
  padding: 3rem;
  width: 100%;
  position: relative;
  z-index: 95;

  ${CalendarSVG} {
    display: none;
  }

  @media (min-width: 1024px) {
    width: 100%;
    max-width: var(--gridWidth);
    margin: 0 auto 10rem auto;
    padding: 8.6rem 0 2rem 0;

    ${CalendarSVG} {
      display: block;
      position: absolute;
      bottom: 35%;
      right: 6%;
    }
  }
`;

const BackgroundMapCustom = styled(BackgroundMap)`
  ${WorkPinSVG} {
    height: auto;
    width: 60px;
    grid-column: 3;
    grid-row: 6;
  }

  ${CalendarPinSVG} {
    height: auto;
    width: 70px;
    grid-column: 8;
    grid-row: 6;
  }

  ${BlueDotSVG} {
    height: auto;
    width: 70px;
    grid-column: 2;
    grid-row: 12;
  }

  ${ActivityCardHands} {
    z-index: 120;
    grid-column: 2 / 7;
    grid-row: 1 / 3;
  }

  ${ActivityCardMichael} {
    z-index: 12;
    grid-column: 5 / 11;
    grid-row: 8 / 12;
  }

  @media (min-width: 1080px) {
    ${WorkPinSVG} {
      height: auto;
      width: 98px;
      grid-column: 8;
      grid-row: 5;
    }

    ${CalendarPinSVG} {
      height: auto;
      width: 166px;
      z-index: 9;
      grid-column: 6;
      grid-row: 6;
    }

    ${ActivityCardHands} {
      grid-column: 2 / 8;
      grid-row: 1 / 3;
    }

    ${ActivityCardMichael} {
      z-index: 12;
      grid-column: 9 / 13;
      grid-row: 7 / 12;
    }

    ${BlueDotSVG} {
      margin-top: 1.5rem;
    }
  }
`;
